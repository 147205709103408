
.node-type-forum {
	.layout-3col__full h1 {
		margin-bottom: 0px;
	}
	.field-name-body {
		margin-left: 20px;
		border-bottom: 0px solid black;
	}
	.comment {
		margin-left: 20px;
		padding: 20px 10px 20px 40px;
		margin-bottom: 20px;
	}
	.comment {
		background-color: #c6c4c4;
		border-radius: 10px;
	}
	article header {
		background-color: transparent;
		padding-top: 0px;
	}
	.comment p {
		margin: 5px 0px;
	}
	.indented {
		border-left: 2px solid grey;
		.comment {
			background-color: #b7d3b3;
			border-radius: 2px;
		}
		.comment {
			background-color: #d6e0fc;
			border-radius: 2px;
		}
		.indented .indented .comment {
			background-color: #b3adce;
			border-radius: 2px;
		}

	}
	.submitted {
		font-size: 1em;
		font-style: italic;
		margin: 0px;
	}
	#comment-form {
		margin-left: 30px;
		background-color: #d8d2d2;
		border-radius: 12px;
		padding: 30px;
		border: 1px solid grey;
    }
	.field-name-body {
		font-size: 105%;
		max-width: 800px;
		padding: 0 0 0 20px;
		margin: 20px 0 20px 0px;
		border-left: 7px solid grey;
		border-radius: 0px;
		br {
			margin-bottom: 10px;
		}
	}
	.field-name-taxonomy-forums {
		font-weight: 900;
		margin-bottom: 20px;
	}
}

#forum {
	td {
		padding: 5px;
	}
	tr.odd {
		background-color: #c6c4c4;
	}
	.title a {
		font-size: 1.2em;
		font-weight: 900;
	}

}

.comment .group-left {
	width: 15%;
	float: left;
	.username {
		font-size: 110%;
		font-weight: 900;
	}
}

.comment .group-right {
	width: 80%;
	float: right;
	a {
		font-size: 1.25rem;
		line-height: 2.25rem;
		font-weight: 900;
	}
	ul a {
		font-size: 100%;
		line-height: 150%;
	}
}

article {
	position: relative;
}

.comment footer {
	background-color: transparent;
}

//.comment-reply {
//	position: absolute;
//		bottom: 20px;
//		right: 20px;
//}

//.forum-post-panel-main .field-name-comment-body {
//	border-bottom: 1px solid black;
//}

.author-signature {
	border: 1px solid grey;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 10px;
	margin: 10px;
	background-color: white;
	p {
		margin: 0px;
	}
}

.about-signature {
	margin: 4px 4px 4px 0px;
	h4 {
		margin: 0px;
		color: $nav-color;
	}
}

.forum-post-panel-main {
	min-height: 130px;
}

