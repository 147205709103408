#user-login-form {
	div {
		div {
			float: left;
			margin-right: 20px;
			color: green;
			a {
				color: green;
			}
		}
		div.item-list {
			margin-top: 1rem;
		}
		div.form-actions {
			margin-top: 3rem;
		}
	}
}

#block-user-login {
	a {
		border: 1px solid black;
		background-color: $primary-color;
		color: white;
		padding: 5px;
		border-radius: 5px;
	}
	a:hover {
		background-color: $nav-color ;
	}
}

.toboggan-unified {
	width: 55%;
	text-align: left;
	margin: 5px auto;
	.form-item {
		text-align: left;
	}
	.description {
		font-size: .7em;
		font-style: italic;
	}
	input {
		border-radius: 3px;
		padding: 10px;
	  border: solid 2px #dcdcdc;
	  transition: box-shadow 0.3s, border 0.3s;
	}
	input:focus,
	input.focus {
	  border: solid 1px #707070;
	  box-shadow: 0 0 5px 1px #969696;
	}
	fieldset {
		border-radius: 4px;
		background-color: #e2e2e2;
		border-color: grey;
		margin-bottom: 10px;
		legend {
			display: none;
		}
		fieldset {
			border: none;
			padding: 0px;
			margin: 0px;
		}
	}
}

.toboggan-unified #login-message {
	font-size: 1.2em;
	margin-bottom: 20px;
	text-align: center;
}

.toboggan-unified #login-links a {
	display: block;
	text-align: center;
  padding: 3% 2%;
  border: 1px #666 solid;
  border-radius: 4px;
  font-size: .9em;
  margin: 10px 5px;
  width: 45%;
  float: left;
}

.toboggan-unified #login-links a {
  margin: 10px 5px;
  width: 45%;
}

#login-links {
	clear: both;
	margin-bottom: 20px;
	height: 70px;
}

.toboggan-unified #login-links a.lt-active {
    background-color: $nav-color;
    border: 2px #777 solid;
}

.toboggan-unified #login-links a {
    background-color: $nav-color;
}

.toboggan-unified #login-links a.login-link:hover {
    background-color: $nav-color;
    box-shadow: 0 0 3px 1px #969696;
}

@media only screen and (max-width: 900px) {
	.toboggan-unified {
		width: 70%;
	}
	.toboggan-unified #login-links a {
    margin: 10px 5px;
    width: 90%;
	}
}

@media only screen and (max-width: 500px) {
	.toboggan-unified {
		width: 90%;
	}
}

.addressfield-container-inline .state {
	height: 45px;
	border: 0px solid red;
	background-color: white;
}

// Profiles

#user-profile-form label {
	font-size: 14px;
}

#user-profile-form input {
	// border: 1px solid red;
	border: 1px solid black;
}

#user-profile-form .form-item {
	margin: .7rem 0;
}

.field-type-text.field-name-field-idv-name-first {
	width: 35%;
	margin: 0px 5% 0px 0;
	float: left;
}

.field-type-text.field-name-field-idv-name-middle {
	width: 15%;
	margin: 0px 5% 0px 0;
	float: left;
}

.field-type-text.field-name-field-idv-name-last {
	width: 35%;
	margin: 0px 5% 0px 0;
}

.field-type-text.field-name-field-idv-linkedin,
.field-type-text.field-name-field-idv-facebook,
.field-type-text.field-name-field-idv-twitter,
.field-type-text.field-name-field-idv-website,
.field-type-text.field-name-field-idv-email-alt,
.field-type-list-text.field-name-field-idv-phone-alt-type,
.field-type-text.field-name-field-idv-phone-alt,
.field-type-list-text.field-name-field-idv-phone-type,
.field-type-text.field-name-field-idv-phone,
.field-type-text.field-name-field-idv-initials,
.field-type-text.field-name-field-idv-pin {
	width: 100%;
	margin: 3px 5% 3px 0;
	height: 70px;
}

.field-type-text.field-name-field-idv-linkedin {
	width: 95%;
	margin: 3px 5% 3px 0;
	float: left;
	height: 70px;
}

#user-profile-form #profile-individual-field-idv-street-address-add-more-wrapper input {
	width: 100%;
}

#register-form .form-item-email-consent {
	margin: 20px 0px 20px 0px;
}

#user-profile-form fieldset {
	width: 100%;
}

