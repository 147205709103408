/* Tooltip container */
.tooltip {
	position: relative;
	/*	display: inline-block;*/
	max-width: 400px;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	max-width: 400px;
	background-color: #555;
	color: #fff;
	text-align: center;
	padding: 5px 10px;
	border-radius: 6px;

	/* Position the tooltip text */
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 0%;
	margin-left: 0px;

	/* Fade in tooltip */
	opacity: 0;
	transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 20%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}