// Variables specific to Silicon Theme

$silicon-page-width:      1100px;
$border-radius:              7px;

// Silicon Theme Colors

$green                   :   #00682D;
$gold                    :   #E0A300;


// HEADER and FOOTER and PAGE HEADERS and BUTTONS
$primary-color           :   $green;
$primary-color-text      :   white;
$primary-color-hover     :   $gold;


// Main Nav Bar
$nav-color               :   $gold;
$sub-nav-color           :   #ffbb00;
$nav-links               :   white;
$nav-links-hover         :   $primary-color;

  // If different from secondary color
$page-headlines          :     $nav-color;
$page-links              :     $nav-color;
$page-links-hover        :     $primary-color;

  // If different from primary color
$footer-background       :    $primary-color;
$footer-text             :    $primary-color-text;
$footer-hover            :    $primary-color-hover;


$table-background        :    #F1F1F1;
$table-border            :    #adadad;

