header.banner {
  padding-left: 10px;
  padding-right: 10px;
}

.header__logo img {
  max-height: 100px;
}

#navbar {
  width: 100%;
}

header {
  background: #fff;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1em 0; 

  #header-content {
    margin: 0px auto;
    max-width: $silicon-page-width;
    clear: both;
  }

  .branding {
    width: 60%;
    float: left;
  }

  .silicon-login {
    width: auto;
    float: right;
    margin-top: 22px;
    a {
      padding: 8px 20px;
    }
  }
  .header__region {
    margin: 0px;
    padding: 0px;
  }
}

@media only screen and (max-width: 700px) {
  body .responsive-menus.responsified span.toggler {
    background: none;
  }
  body .responsive-menus.responsified .responsive-menus-simple {
    background: grey;
  }
  body header.banner {
    height: auto;
    text-align: center;
    background-size: cover;
    a {
      font-size: 12px;
      line-height: 10px;
    }
  }
  #header-content {
    display: flex;
    flex-wrap: wrap;
  }
  header .silicon-login {
    order: 1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    display: block;
    a.sppx-button {
      width: 100%;
    }
  }
  header .branding {
    order: 2;
    float: none;
      .header__logo {
        float: none;
      }
  }
  header #header-content .branding {
    width: 80%;
    margin: 0px 10%;
    .header__name-and-slogan {
      float: none;
    }
  }
}





