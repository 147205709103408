// Prevent input boxes from getting too big on mobile screens 

.field-type-text input {
	width: 100%;
}

.button {
	padding: 5px;
	border: 1px solid grey;
	background-color: #F1F1F1;
	:hover {
		background-color: green;
	} 
}

@media only screen and (min-width: 700px) {
	.form-wrapper .fieldset-wrapper input {
		max-width: 500px;
	}
	form table {
		max-width: 500px;
	}
}

.view-issue-labels form table {
	max-width: 100%;
}

.stripe-wrapper {
	margin-bottom: 20px;
}