* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px !important;
}

body .layout-center {
  background-color: #fff;
  max-width: none;
  padding: 0;
  max-width: 1100px;
  min-height: 600px;
}

.layout-center .layout-swap__top {
  height: 0px;
}

.main-content-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

article header {
  padding: 0px;
  background-color: none;
}

/*.region-highlighted {
  z-index: 100;
}*/
input {
  border-radius: 4px;
  border-width: 1px;
}

.issue-column-one {
  margin-right: 450px;
}

.issue-column-two {
  width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  border: 1px solid grey;
  background-color: #F1F1F1;
}

.issue-column-two b {
  color: green;
}

@media only screen and (max-width: 600px) {
  #login-links a {
    margin-top: 25px;
    display: block;
    font-size: .7em;
    line-height: 1.3;
  }
  .toboggan-unified #login-message {
    text-align: center;
    font-size: 1.3em;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 1100px) {
  body .layout-center {
    margin: 20px;
  }
}

.ds-2col-stacked > .group-left {
  width: 52%;
}

.ds-2col-stacked > .group-left div.field-item p:first-child {
  margin-top: 0px;
}

.ds-2col-stacked > .group-right {
  width: 40%;
  padding: 2% 3%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: #F1F1F1;
}

.section-issues .field-name-field-issue-geocode {
  margin-top: 30px;
}

@media only screen and (max-width: 800px) {
  .ds-2col-stacked > .group-left {
    width: 100%;
    padding: 0;
  }
  .ds-2col-stacked > .group-right {
    width: 100%;
    padding: 5%;
    margin: 10px 0px 30px;
  }
}

.view-mode-fundraising .field-name-field-issue-raised-to-date,
.view-mode-special_issue .field-name-field-issue-raised-to-date,
.view-mode-full .field-name-field-issue-raised-to-date {
  text-align: center;
  font-weight: 900;
  padding-bottom: 0px;
  margin-top: 10px;
}

.view-mode-fundraising .field-name-field-issue-raised-to-date .field-label,
.view-mode-special_issue .field-name-field-issue-raised-to-date .field-label,
.view-mode-full .field-name-field-issue-raised-to-date .field-label {
  font-size: 15px;
  line-height: 30px;
}

.view-mode-fundraising .field-name-field-issue-raised-to-date .field-items,
.view-mode-special_issue .field-name-field-issue-raised-to-date .field-items,
.view-mode-full .field-name-field-issue-raised-to-date .field-items {
  font-size: 40px;
  line-height: 40px;
}

.view-mode-fundraising .field-name-field-issue-investor-label,
.view-mode-special_issue .field-name-field-issue-investor-label,
.view-mode-full .field-name-field-issue-investor-label {
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
}

#block-views-issuer-questions-block-1 {
  background-color: #F1F1F1;
  border: 1px solid black;
  border-radius: 7px;
  padding: 5px 30px 30px;
  margin: 40px 0px 20px;
}

#block-views-issuer-questions-block-1 p {
  margin: 7px 0px 20px;
}

#block-views-issuer-questions-block-1 .block__title {
  margin-bottom: 0px;
}

#block-views-issuer-questions-block-1 .button-wrapper {
  height: 45px;
  margin: 10px 0px;
  padding-top: 10px;
  display: block;
}

#block-views-issuer-questions-block-1 .sppx-button {
  margin: 0px 20px 0px;
  padding: 8px 15px;
}

#block-views-issuer-questions-block-1 .questions {
  font-size: 14px;
  font-style: italic;
  margin: 3px 0px 10px;
  display: block;
}

#block-views-issuer-questions-block {
  background-color: #F1F1F1;
  border: 1px solid #a8aa00;
  border-radius: 7px;
  padding: 5px 30px;
  margin: 20px 0px;
}

.quicktabs_main {
  position: relative;
}

.quicktabs-tabpage {
  display: block;
  visibility: visible;
  width: 100%;
}

.quicktabs-tabpage.quicktabs-hide {
  display: block;
  visibility: hidden;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -1;
}

.ui-helper-reset {
  line-height: 1.5em;
}

.ui-accordion .ui-accordion-content-active,
.ui-accordion .views-accordion-header {
  padding: 1.5em 2em 1.5em 4.5em;
}

.ui-accordion .ui-accordion-content-active p,
.ui-accordion .views-accordion-header p {
  margin: 15px 0px;
  margin-bottom: 30px;
}

.ui-accordion span.views-label {
  color: #00682D;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 900;
}

.ui-accordion .field-content {
  margin-top: 20px;
}

#block-sppx-ticker-ticker {
  font-family: "Courier New", Courier, monospace;
  margin-top: 10px;
  margin-bottom: 20px;
}

#block-sppx-ticker-ticker .issue-item {
  font-size: 30px;
  margin-right: 300px;
}

#block-sppx-ticker-ticker .issue-item a {
  text-decoration: none;
}

#block-sppx-ticker-ticker .issue-item a:hover {
  font-weight: 900;
}

.node-geppetto-smart-document .field {
  width: 60%;
  float: left;
}

.node-geppetto-smart-document .render-pdf {
  width: 300px;
}

.node-geppetto-smart-document .render-pdf .help-text {
  font-size: 75%;
}

div .collapsiblock a {
  width: 250px;
  background-size: 15px;
}

div .collapsiblockCollapsed a {
  background-size: 15px;
}

.page-node-360 .block-quicktabs .views-row .views-field {
  border: none;
}

.page-node-360 .templates div {
  width: 420px;
}

.page-node-360 .templates .wide {
  text-align: left;
}

.page-node-360 .templates .medium {
  width: 120px;
}

.page-node-360 .templates .narrow {
  width: 80px;
}

.page-node-360 .templates a {
  color: #00682D;
}

.page-node-360 .templates a:hover {
  color: #a1c6b0;
}

.page-node-360 .templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
}

.page-node-360 .views-row {
  height: 100%;
  border: 0px solid pink;
  padding: 3px 20px;
}

.page-node-360 .views-row.views-row-even {
  background-color: #F1F1F1;
}

.page-node-360 .ui-accordion .ui-accordion-content-active {
  padding: 10px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #a1c6b0;
}

.diff td {
  vertical-align: top;
  white-space: pre;
  white-space: pre-wrap;
  font-family: monospace;
}

.diff th {
  background-color: lightgrey;
}

.diff .diffDeleted {
  background-color: pink;
}

.diff .diffInserted {
  background-color: lightgreen;
}

.node-317 header {
  display: none;
}

#block-block-12 {
  border: 1px solid grey;
  padding: 2% 3%;
  background-color: #F1F1F1;
  border-radius: 4px;
  margin-top: 120px;
}

#sppx-funding-options-ach-form,
.payment {
  width: 95%;
  padding: 2%;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Responsive video with fixed aspect ratio 16:9. */
/* See https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php */
.embedded-video .player {
  position: relative;
  padding-top: 56.25%;
  /* =16/9 */
}

.embedded-video .player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .front .layout-3col__full {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.maintenance-page.in-maintenance header {
  margin-top: 100px;
}

.maintenance-page.in-maintenance header a,
.maintenance-page.in-maintenance .main-content {
  display: block;
  float: none;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .ds-2col-stacked > .group-left {
    float: none;
    width: 95%;
    margin-left: 0;
    margin-bottom: 5%;
    padding: 2% 3%;
  }
  .embedded-video .player {
    position: relative;
    padding-top: 50%;
    max-width: 95%;
  }
  .field-name-field-issue-photo-gallery {
    max-width: 95%;
  }
  .ds-2col-stacked > .group-right {
    float: none;
    width: 100%;
    overflow: hidden;
  }
  body .gallery-frame,
  body .galleryformatter .gallery-slides,
  body .galleryformatter .gallery-thumbs {
    width: 300px !important;
  }
}

.field-name-field-issue-membership-button a,
.field-name-field-issue-invest-button a {
  background-color: #E0A300;
  border-radius: 7px;
}

.field-name-field-issue-membership-button a:hover,
.field-name-field-issue-invest-button a:hover {
  background-color: #ffbb00;
}

.group-issues-button,
.group-issue-buttons {
  position: relative;
  height: 130px;
}

.field-name-field-issue-membership-button a,
.field-name-field-issue-discuss-button a {
  width: 100%;
  display: block;
}

.field-name-field-issue-membership-button {
  width: 45%;
  display: block;
  position: absolute;
  top: 54px;
  right: 0px;
}

.field-name-field-issue-membership-button a {
  float: right;
}

.field-name-field-issue-invest-button {
  width: 45%;
  padding-top: 20px;
  position: absolute;
  top: 35px;
  left: 0px;
}

.field-name-field-issue-invest-button a {
  float: left;
}

input[type='submit'].sppx-button,
.sppx-button,
a.sppx-button {
  background-color: #E0A300;
  border-radius: 7px;
  color: white;
  padding: 3px 12px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
}

input[type='submit'].sppx-button:hover,
.sppx-button:hover,
a.sppx-button:hover {
  background-color: #ffbb00;
  color: white;
  font-weight: 500;
}

.group-issue-buttons a.sppx-button,
.group-issue-buttons a.sppx-button:hover,
.group-issues-button a.sppx-button,
.group-issues-button a.sppx-button:hover {
  width: 100%;
}

.field-name-field-issue-invest-button .sp-hide {
  display: none;
}

.render-pdf {
  float: right;
  text-align: center;
}

.render-pdf a {
  float: right;
}

#copyright p {
  font-size: 85%;
  margin: 10px;
  color: #bbb;
}

body {
  position: relative;
}

footer {
  width: 100%;
  height: 100%;
  min-height: 350px;
  padding: 30px 5px;
  margin-top: 30px;
  background-color: #00682D;
}

footer a, footer p, footer h1, footer h2 {
  color: white;
}

footer a:visited {
  color: #cccccc;
}

.sub-footer,
.footer-region {
  max-width: 1100px;
  margin: 0px auto;
  clear: both;
}

.footer-column {
  width: 30%;
  min-height: 20px;
  margin: 0 1.5%;
  float: left;
}

.sppx-copyright {
  font-size: 14px;
}

.region-footer-bottom {
  width: 100%;
  font-size: 14px;
}

#block-block-14 {
  text-align: center;
}

.field-type-text input {
  width: 100%;
}

.button {
  padding: 5px;
  border: 1px solid grey;
  background-color: #F1F1F1;
}

.button :hover {
  background-color: green;
}

@media only screen and (min-width: 700px) {
  .form-wrapper .fieldset-wrapper input {
    max-width: 500px;
  }
  form table {
    max-width: 500px;
  }
}

.view-issue-labels form table {
  max-width: 100%;
}

.stripe-wrapper {
  margin-bottom: 20px;
}

.node-type-forum .layout-3col__full h1 {
  margin-bottom: 0px;
}

.node-type-forum .field-name-body {
  margin-left: 20px;
  border-bottom: 0px solid black;
}

.node-type-forum .comment {
  margin-left: 20px;
  padding: 20px 10px 20px 40px;
  margin-bottom: 20px;
}

.node-type-forum .comment {
  background-color: #c6c4c4;
  border-radius: 10px;
}

.node-type-forum article header {
  background-color: transparent;
  padding-top: 0px;
}

.node-type-forum .comment p {
  margin: 5px 0px;
}

.node-type-forum .indented {
  border-left: 2px solid grey;
}

.node-type-forum .indented .comment {
  background-color: #b7d3b3;
  border-radius: 2px;
}

.node-type-forum .indented .comment {
  background-color: #d6e0fc;
  border-radius: 2px;
}

.node-type-forum .indented .indented .indented .comment {
  background-color: #b3adce;
  border-radius: 2px;
}

.node-type-forum .submitted {
  font-size: 1em;
  font-style: italic;
  margin: 0px;
}

.node-type-forum #comment-form {
  margin-left: 30px;
  background-color: #d8d2d2;
  border-radius: 12px;
  padding: 30px;
  border: 1px solid grey;
}

.node-type-forum .field-name-body {
  font-size: 105%;
  max-width: 800px;
  padding: 0 0 0 20px;
  margin: 20px 0 20px 0px;
  border-left: 7px solid grey;
  border-radius: 0px;
}

.node-type-forum .field-name-body br {
  margin-bottom: 10px;
}

.node-type-forum .field-name-taxonomy-forums {
  font-weight: 900;
  margin-bottom: 20px;
}

#forum td {
  padding: 5px;
}

#forum tr.odd {
  background-color: #c6c4c4;
}

#forum .title a {
  font-size: 1.2em;
  font-weight: 900;
}

.comment .group-left {
  width: 15%;
  float: left;
}

.comment .group-left .username {
  font-size: 110%;
  font-weight: 900;
}

.comment .group-right {
  width: 80%;
  float: right;
}

.comment .group-right a {
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: 900;
}

.comment .group-right ul a {
  font-size: 100%;
  line-height: 150%;
}

article {
  position: relative;
}

.comment footer {
  background-color: transparent;
}

.author-signature {
  border: 1px solid grey;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: white;
}

.author-signature p {
  margin: 0px;
}

.about-signature {
  margin: 4px 4px 4px 0px;
}

.about-signature h4 {
  margin: 0px;
  color: #E0A300;
}

.forum-post-panel-main {
  min-height: 130px;
}

header.banner {
  padding-left: 10px;
  padding-right: 10px;
}

.header__logo img {
  max-height: 100px;
}

#navbar {
  width: 100%;
}

header {
  background: #fff;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1em 0;
}

header #header-content {
  margin: 0px auto;
  max-width: 1100px;
  clear: both;
}

header .branding {
  width: 60%;
  float: left;
}

header .silicon-login {
  width: auto;
  float: right;
  margin-top: 22px;
}

header .silicon-login a {
  padding: 8px 20px;
}

header .header__region {
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 700px) {
  body .responsive-menus.responsified span.toggler {
    background: none;
  }
  body .responsive-menus.responsified .responsive-menus-simple {
    background: grey;
  }
  body header.banner {
    height: auto;
    text-align: center;
    background-size: cover;
  }
  body header.banner a {
    font-size: 12px;
    line-height: 10px;
  }
  #header-content {
    display: flex;
    flex-wrap: wrap;
  }
  header .silicon-login {
    order: 1;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    display: block;
  }
  header .silicon-login a.sppx-button {
    width: 100%;
  }
  header .branding {
    order: 2;
    float: none;
  }
  header .branding .header__logo {
    float: none;
  }
  header #header-content .branding {
    width: 80%;
    margin: 0px 10%;
  }
  header #header-content .branding .header__name-and-slogan {
    float: none;
  }
}

#user-login-form div div {
  float: left;
  margin-right: 20px;
  color: green;
}

#user-login-form div div a {
  color: green;
}

#user-login-form div div.item-list {
  margin-top: 1rem;
}

#user-login-form div div.form-actions {
  margin-top: 3rem;
}

#block-user-login a {
  border: 1px solid black;
  background-color: #00682D;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

#block-user-login a:hover {
  background-color: #E0A300;
}

.toboggan-unified {
  width: 55%;
  text-align: left;
  margin: 5px auto;
}

.toboggan-unified .form-item {
  text-align: left;
}

.toboggan-unified .description {
  font-size: .7em;
  font-style: italic;
}

.toboggan-unified input {
  border-radius: 3px;
  padding: 10px;
  border: solid 2px #dcdcdc;
  transition: box-shadow 0.3s, border 0.3s;
}

.toboggan-unified input:focus,
.toboggan-unified input.focus {
  border: solid 1px #707070;
  box-shadow: 0 0 5px 1px #969696;
}

.toboggan-unified fieldset {
  border-radius: 4px;
  background-color: #e2e2e2;
  border-color: grey;
  margin-bottom: 10px;
}

.toboggan-unified fieldset legend {
  display: none;
}

.toboggan-unified fieldset fieldset {
  border: none;
  padding: 0px;
  margin: 0px;
}

.toboggan-unified #login-message {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
}

.toboggan-unified #login-links a {
  display: block;
  text-align: center;
  padding: 3% 2%;
  border: 1px #666 solid;
  border-radius: 4px;
  font-size: .9em;
  margin: 10px 5px;
  width: 45%;
  float: left;
}

.toboggan-unified #login-links a {
  margin: 10px 5px;
  width: 45%;
}

#login-links {
  clear: both;
  margin-bottom: 20px;
  height: 70px;
}

.toboggan-unified #login-links a.lt-active {
  background-color: #E0A300;
  border: 2px #777 solid;
}

.toboggan-unified #login-links a {
  background-color: #E0A300;
}

.toboggan-unified #login-links a.login-link:hover {
  background-color: #E0A300;
  box-shadow: 0 0 3px 1px #969696;
}

@media only screen and (max-width: 900px) {
  .toboggan-unified {
    width: 70%;
  }
  .toboggan-unified #login-links a {
    margin: 10px 5px;
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .toboggan-unified {
    width: 90%;
  }
}

.addressfield-container-inline .state {
  height: 45px;
  border: 0px solid red;
  background-color: white;
}

#user-profile-form label {
  font-size: 14px;
}

#user-profile-form input {
  border: 1px solid black;
}

#user-profile-form .form-item {
  margin: .7rem 0;
}

.field-type-text.field-name-field-idv-name-first {
  width: 35%;
  margin: 0px 5% 0px 0;
  float: left;
}

.field-type-text.field-name-field-idv-name-middle {
  width: 15%;
  margin: 0px 5% 0px 0;
  float: left;
}

.field-type-text.field-name-field-idv-name-last {
  width: 35%;
  margin: 0px 5% 0px 0;
}

.field-type-text.field-name-field-idv-linkedin,
.field-type-text.field-name-field-idv-facebook,
.field-type-text.field-name-field-idv-twitter,
.field-type-text.field-name-field-idv-website,
.field-type-text.field-name-field-idv-email-alt,
.field-type-list-text.field-name-field-idv-phone-alt-type,
.field-type-text.field-name-field-idv-phone-alt,
.field-type-list-text.field-name-field-idv-phone-type,
.field-type-text.field-name-field-idv-phone,
.field-type-text.field-name-field-idv-initials,
.field-type-text.field-name-field-idv-pin {
  width: 100%;
  margin: 3px 5% 3px 0;
  height: 70px;
}

.field-type-text.field-name-field-idv-linkedin {
  width: 95%;
  margin: 3px 5% 3px 0;
  float: left;
  height: 70px;
}

#user-profile-form #profile-individual-field-idv-street-address-add-more-wrapper input {
  width: 100%;
}

#register-form .form-item-email-consent {
  margin: 20px 0px 20px 0px;
}

#user-profile-form fieldset {
  width: 100%;
}

.content {
  background: #fff;
  max-width: 1170px;
  margin: 0 auto;
  color: #606060;
}

.content .layout-3col__full {
  padding-left: 0;
  padding-right: 0;
}

.page-partners h1 {
  font-size: 16px;
  margin: .5rem 0px;
}

.page-partners tr {
  background-color: white;
}

.page-partners .views-field-field-org-logo {
  width: 33%;
}

.page-partners .views-field-field-org-description {
  width: 66%;
}

.layout-center .layout-swap {
  padding-top: 0px;
}

.responsive-menus.responsified span.toggler {
  padding: 10px 0px;
  display: block;
  color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #000;
  font-size: 1.35em;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.responsive-menus.responsified .responsive-menus-simple li a {
  padding: .4em 5%;
}

.responsive-menus.responsified .responsive-menus-simple li ul li a {
  padding: .4em 5% .4em 10%;
}

.block-superfish {
  width: 100%;
  margin: 0px auto;
}

#navbar {
  width: 100%;
  background-color: #E0A300;
}

#superfish-1 {
  max-width: 1100px;
  margin: 0px auto;
  padding: .75em 0px;
}

#navbar span,
#navbar a,
#navbar a:active {
  color: white;
  text-decoration: none;
  padding: 0px .75em;
  line-height: 1.5;
}

ul#superfish-1 li ul {
  margin-top: -5px;
}

ul#superfish-1 li ul li a {
  padding: .75em;
  line-height: 1.5;
}

ul#superfish-1 {
  display: flex;
  padding-left: 0;
}

ul#superfish-1 li {
  display: inline;
}

ul#superfish-1 li a,
ul#superfish-1 li a:active {
  color: white;
  text-decoration: none;
}

ul#superfish-1 li a:hover {
  color: black;
}

ul#superfish-1 li a.active {
  color: #f15b61;
}

ul#superfish-1 li ul li {
  background-color: #E0A300;
}

ul#superfish-1 li ul li a,
ul#superfish-1 li ul li a:active,
ul#superfish-1 li ul li a:visited {
  color: white;
}

ul#superfish-1 li ul li a:hover {
  color: black;
}

ul#superfish-1 li.first a {
  border-left: 0;
  color: white;
}

.sf-accordion-toggle {
  display: none;
}

.responsive-menus.responsified span.toggler {
  border-radius: 0px;
}

.region-navigation {
  max-width: 1100px;
  margin: 0px auto;
}

#navbar li ul li a:active {
  padding: .75em;
}

.block-superfish ul#superfish-1 li ul li:hover {
  background-color: #7a5900;
}

.block-superfish ul#superfish-1 li ul li:hover a {
  color: white;
}

#slideshow {
  overflow: hidden;
}

.flexslider {
  margin-bottom: 0 !important;
  border: none !important;
  max-height: 350px;
  overflow: hidden;
}

.flexslider .slides img {
  position: relative;
}

.flexslider .flex-direction-nav a:before {
  font-size: 34px !important;
}

@media only screen and (max-width: 900px) {
  #slideshow {
    overflow: hidden;
    border: 2px solid green;
  }
  ul.slides {
    border: 2px solid green;
  }
  .flexslider {
    max-height: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .flexslider {
    max-height: 150px;
  }
}

.quicktabs_main {
  position: relative;
}

.quicktabs-tabpage {
  display: block;
  visibility: visible;
  width: 100%;
}

.quicktabs-tabpage .views-row:hover {
  cursor: pointer;
}

.quicktabs-tabpage.quicktabs-hide {
  display: block;
  visibility: hidden;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -1;
}

ul.quicktabs-tabs li a {
  padding: 2px 22px;
  border: 1px solid grey;
  background-color: #d0e0d7;
  border-radius: 3px;
  margin-right: 15px;
  color: #062F4F;
}

@media only screen and (max-width: 375px) {
  ul.quicktabs-tabs li a {
    margin-right: 15px;
  }
}

ul.quicktabs-tabs li.active a {
  background-color: white;
}

ul.quicktabs-tabs li:not(.active) a:hover {
  background-color: #00682D;
  color: white;
}

table {
  border: 1px solid #ddd;
}

article td {
  display: table-cell;
  padding: 5px 10px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

tr {
  padding: 5px 5px;
}

.block-quicktabs {
  margin-top: 30px;
}

.block-quicktabs .views-row {
  font-size: 13px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: wrap;
  width: 100%;
}

.block-quicktabs .views-row .views-field {
  flex-basis: auto;
  border: .5px solid #d8d2d2;
  padding: 3px;
  width: 100px;
  text-align: center;
  line-height: 20px;
}

.block-quicktabs .views-row .views-field.label {
  font-size: 14px;
  font-weight: 900;
}

.block-quicktabs .views-row .views-field-title {
  flex-grow: 3;
  width: 200px;
  overflow: hidden;
}

.block-quicktabs .views-row .views-field-nothing {
  flex-grow: 2;
  width: 160px;
}

.block-quicktabs .views-row .views-field-field-issue-type,
.block-quicktabs .views-row .views-field-field-issue-amount-target,
.block-quicktabs .views-row .views-field-field-issue-raised-to-date,
.block-quicktabs .views-row .views-field-field-issue-invest-min,
.block-quicktabs .views-row .views-field-field-issue-amount-target,
.block-quicktabs .views-row .views-field-field-issue-amount-min,
.block-quicktabs .views-row .views-field-nothing-1 {
  flex-grow: 1;
}

@media only screen and (max-width: 700px) {
  .block-quicktabs .views-row {
    margin-bottom: 10px;
  }
  ul.quicktabs-tabs {
    padding: 0;
  }
}

.progress-bar__bar, .progress .bar {
  height: 15px;
}

.layout-center .progress .filled {
  background-color: #00682D;
  background-image: none;
  height: 13px;
}

.layout-center .views-field-field-issue-amount-target .progress .filled {
  background-color: #D8A200;
  background-image: none;
  height: 13px;
}

#progress {
  margin-bottom: 20px;
}

table td {
  padding: 5px;
}

table tr.odd {
  background-color: #F1F1F1;
}

table tr {
  border: 1px solid #adadad;
}

.tableheader-processed caption {
  text-align: left;
  font-size: 20px;
  font-weight: 800;
}

.view-offices .view-content,
.view-tile-view .view-content {
  display: flex;
  flex-wrap: wrap;
  border: 0px solid blue;
}

.view-tile-view.views-row,
.view-tile-view .views-row {
  border: 1px solid grey;
  box-shadow: 10px 10px 5px #888888;
  width: 30.11%;
  margin: 1.5%;
  padding: 0;
  margin-bottom: 30px;
}

.view-tile-view.views-row .group-tile-content,
.view-tile-view .views-row .group-tile-content {
  padding: 0% 6% 3% 6%;
  margin: 0px;
  margin-top: -10px;
  position: relative;
}

.view-tile-view.views-row .group-tile-content .field,
.view-tile-view .views-row .group-tile-content .field {
  display: block;
  width: 100%;
  height: auto;
}

.view-tile-view.views-row .group-tile-content .field-label,
.view-tile-view .views-row .group-tile-content .field-label {
  position: relative;
  left: 0px;
}

.view-tile-view.views-row .group-tile-content .field-items .field-item,
.view-tile-view .views-row .group-tile-content .field-items .field-item {
  position: relative;
  right: 0px;
}

.view-tile-view.views-row .field-name-field-issue-percent-funded-bar,
.view-tile-view .views-row .field-name-field-issue-percent-funded-bar {
  text-align: left;
}

.view-tile-view.views-row .field-name-title h2,
.view-tile-view .views-row .field-name-title h2 {
  font-size: 1.3rem;
  line-height: 1.4rem;
}

.view-tile-view.views-row .field-name-field-issue-image,
.view-tile-view .views-row .field-name-field-issue-image {
  margin: 0px;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.view-tile-view.views-row .field-name-field-issue-image img,
.view-tile-view .views-row .field-name-field-issue-image img {
  width: 100%;
  height: auto;
}

.view-tile-view .views-row:hover {
  box-shadow: 0px 0px 15px 0px #242424;
}

@media only screen and (max-width: 1100px) {
  .view-tile-view.views-row,
  .view-tile-view .views-row {
    width: 47%;
  }
}

@media only screen and (max-width: 700px) {
  .view-tile-view.views-row,
  .view-tile-view .views-row {
    width: 97%;
  }
}

.ds-1col a:hover {
  color: inherit;
}

h1 {
  font-size: 18px;
  color: #00682D;
}

.layout-3col__full h1 {
  font-size: 2em;
  line-height: 1.2em;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}

.layout-center .block-user a {
  text-decoration: none;
  color: #00682D;
}

a:hover {
  text-decoration: underline;
}

.header__site-name a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 14px;
    line-height: 18px;
  }
  h1 {
    font-size: 16px;
    line-height: 18px;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  /*	display: inline-block;*/
  max-width: 400px;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 400px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0%;
  margin-left: 0px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.flag-outer {
  background-color: #00682D;
  border-radius: 7px;
  padding: 4px;
  width: 160px;
  margin: 0px auto;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  color: white;
}

.flag-outer a {
  color: white;
}

.flag-outer:hover {
  background-color: #ffbb00;
  color: black;
}

.flag-outer:hover a {
  color: black;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
