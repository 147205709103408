body {
	position: relative;
}

footer {
	width: 100%;
	height: 100%;
	min-height: 350px;
	padding: 30px 5px;
	margin-top: 30px;
	a, p, h1, h2 {
		color: $primary-color-text;
	}
	a:visited {
		color: darken($primary-color-text, 20%);
	}
	background-color: $footer-background;
}

.sub-footer,
.footer-region {
	max-width: $silicon-page-width;
	margin: 0px auto;
	clear: both;
}

.footer-column {
	//border: 1px solid pink;
	width: 30%;
	min-height: 20px;
	margin: 0 1.5%;
	float: left;
}

.sppx-copyright {
	font-size: 14px;
}

.region-footer-bottom {
	width: 100%;
	font-size: 14px;
}

#block-block-14 {
	text-align: center;
}
