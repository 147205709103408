table {
  border: 1px solid #ddd;
}

article td {
	display: table-cell;
	padding: 5px 10px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

tr {
	padding: 5px 5px;
}

// FAQ



//.ui-accordion .ui-accordion-content {
//	padding: 1em .5em 1em 3.5em;
//}




// Current Opportunities

.block-quicktabs {
	margin-top: 30px;
}

.block-quicktabs .views-row {
	font-size:13px;
	border: 1px solid black;
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	flex-wrap: wrap;
		width: 100%;
	.views-field {
		flex-basis: auto;
		border: .5px solid #d8d2d2;
		padding: 3px;
		width: 100px;
		text-align: center;
		line-height: 20px;
	}

	.views-field.label {
		font-size: 14px;
		font-weight: 900;
	}

	.views-field-title {
		flex-grow: 3;
		width: 200px;
		overflow: hidden;
	}

	.views-field-nothing {
		flex-grow: 2; 
		width: 160px;
	}

	.views-field-field-issue-type,
	.views-field-field-issue-amount-target,
	.views-field-field-issue-raised-to-date,
	.views-field-field-issue-invest-min,
	.views-field-field-issue-amount-target,
	.views-field-field-issue-amount-min,
	.views-field-nothing-1 {
		flex-grow: 1;
	}

}

@media only screen and (max-width: 700px) {
	.block-quicktabs .views-row {
		margin-bottom: 10px;
	}
	ul.quicktabs-tabs {
		padding: 0;
	}
}

// Percent funded


.progress-bar__bar, .progress .bar {
	height: 15px;
}

.layout-center .progress .filled {
	background-color: #00682D;
	background-image: none;
	height: 13px;
}

.layout-center .views-field-field-issue-amount-target .progress .filled {
	background-color: #D8A200;
	background-image: none;
	height: 13px;
}

#progress {
	margin-bottom: 20px;
}

table {
	td {
		padding: 5px;
	}
	tr.odd {
		background-color: $table-background;
	}
	tr {
		border: 1px solid $table-border;
	}
}

.tableheader-processed caption {
	text-align: left;
	font-size: 20px;
	font-weight: 800;
}