.quicktabs_main {
  position: relative;
}
.quicktabs-tabpage {
  display: block;
  visibility: visible;
  width: 100%;
  .views-row:hover {
    cursor: pointer;
  }
}
.quicktabs-tabpage.quicktabs-hide {
  display: block;
  visibility: hidden;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -1;
}

ul.quicktabs-tabs li a {
  padding: 2px 22px;
  border: 1px solid grey;
  background-color: #d0e0d7;
  border-radius: 3px;
  margin-right: 15px;
  color: #062F4F;
}

@media only screen and (max-width: 375px) {
  ul.quicktabs-tabs li a {
    margin-right: 15px;
  }
}

ul.quicktabs-tabs li.active a    {
  background-color: white;
}

ul.quicktabs-tabs li:not(.active) a:hover    {
  background-color: $primary-color;
  color: white;
}
