.view-offices .view-content,
.view-tile-view .view-content {
	display: flex;
	flex-wrap: wrap;
	border: 0px solid blue;
}

.view-tile-view.views-row,
.view-tile-view .views-row {
	border: 1px solid grey;
	box-shadow: 10px 10px 5px #888888;
	width: 30.11%;
	margin: 1.5%;
	padding: 0;
	margin-bottom: 30px;
	.group-tile-content {
		padding: 0% 6% 3% 6%;
		margin: 0px;
		margin-top: -10px;
		position: relative;
		.field {
			display: block;
			width: 100%;
			height: auto;
		}
		.field-label {
			position: relative;
				left: 0px;
		}
		.field-items .field-item {
			position: relative;
				right: 0px;
		}
	}
	.field-name-field-issue-percent-funded-bar {
		text-align: left;
	}
	.field-name-title h2 {
		font-size: 1.3rem;
		line-height: 1.4rem;
	}
	.field-name-field-issue-image {
		margin: 0px;
		width: 100%;
		height: 200px;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
		}
	}
}

.view-tile-view .views-row:hover {
	box-shadow: 0px 0px 15px 0px rgba(36,36,36,1);
}

@media only screen and (max-width: 1100px) {
	.view-tile-view.views-row,
	.view-tile-view .views-row {
		width: 47%;
	}
}

@media only screen and (max-width: 700px) {
	.view-tile-view.views-row,
	.view-tile-view .views-row {
		width: 97%;
	}
}

.ds-1col a:hover {
	color: inherit;
}