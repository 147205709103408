h1 {
	font-size: 18px;
	color: $primary-color;
}

.layout-3col__full h1 {
	font-size: 2em;
	line-height: 1.2em;
	margin-bottom: 1rem;
}

a {
	text-decoration: none;
}

.layout-center .block-user a{
	text-decoration: none;
	color: $primary-color;
}

a:hover {
	text-decoration: underline;
}

.header__site-name a:hover {
	text-decoration: none;
}

@media only screen and (max-width: 600px) {
	p {
		font-size: 14px;
		line-height: 18px;
	}
	h1 {
		font-size: 16px;
		line-height: 18px;
	}
}