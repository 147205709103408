.field-name-field-issue-membership-button,
.field-name-field-issue-invest-button {
	a {
		background-color: $nav-color;
		border-radius: $border-radius;
	}
	a:hover {
		background-color: $sub-nav-color;
	}
}

.group-issues-button,
.group-issue-buttons {
	position: relative;
    height: 130px;
}

.field-name-field-issue-membership-button a,
.field-name-field-issue-discuss-button a {
  width: 100%;
  display: block;
  //position: absolute;
  //  top: 35px;
  //  right: 0px;
  //a {
  //  float: right;
  //}
}

.field-name-field-issue-membership-button {
  width: 45%;
  display: block;
  position: absolute;
    top: 54px;
    right: 0px;
  a {
    float: right;
  }
}

.field-name-field-issue-invest-button {
  width: 45%;
  padding-top: 20px;
  position: absolute;
    top: 35px;
    left: 0px;
  a {
    float: left;
  }
}

input[type='submit'].sppx-button,
.sppx-button,
a.sppx-button {
  background-color: $nav-color;
  border-radius: $border-radius;
  color: white;
  padding: 3px 12px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
}

input[type='submit'].sppx-button:hover,
.sppx-button:hover,
a.sppx-button:hover {
  background-color: $sub-nav-color;
  color: white;
  font-weight: 500;
}


.group-issue-buttons a.sppx-button,
.group-issue-buttons a.sppx-button:hover,
.group-issues-button a.sppx-button,
.group-issues-button a.sppx-button:hover {
  width: 100%;
}

.field-name-field-issue-invest-button {
  .sp-hide {
    display: none;
  }
}

.render-pdf  {
  float: right;
  text-align: center;
  a {
    float: right;
  }
  //border: 1px solid red;
}

