.content {
  background: #fff;
  max-width: 1170px;
  margin: 0 auto;
  color: #606060;

  .layout-3col__full {
    padding-left: 0;
    padding-right: 0;
  }
}

//


// Partners Page Table

.page-partners {
	h1 {
		font-size: 16px;
		margin: .5rem 0px;
	}

	tr {
		background-color: white;
	}
	.views-field-field-org-logo {
		width: 33%;
	}
	.views-field-field-org-description {
		width: 66%;
	}
}

.layout-center .layout-swap {
  padding-top: 0px;
}
