body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .layout-center {
    background-color: #fff;
    max-width: none;
    padding: 0;
    max-width: $silicon-page-width; 
    min-height: 600px;
  }
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px !important;
}


// This is fixing a problem with an empty div that was covering other elements.
// Not sure what the purpose of the div was/is.

.layout-center .layout-swap__top {
  height: 0px;
}

// End fix

.main-content-wrapper {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
}

article header {
  padding: 0px;
  background-color: none;
}

/*.region-highlighted {
  z-index: 100;
}*/

input {
  border-radius: 4px;
  border-width: 1px;
}

.issue-column-one {
  margin-right: 450px
} 

.issue-column-two { 
  width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  border: 1px solid grey;
  background-color: $table-background;
  b {
    color: green;
  }
}

@media only screen and (max-width: 600px) {
  #login-links {
    a {
      margin-top: 25px;
      display: block;
      font-size: .7em;
      line-height: 1.3;
    }
  }
  .toboggan-unified #login-message {
    text-align: center;
    font-size: 1.3em;
    line-height: 1.2;
  }
}


@media only screen and (max-width: $silicon-page-width) {
  body {
    .layout-center {
      margin: 20px;
      input {
      }
    }
  }
}

// Display Suites Layout for Issues


.ds-2col-stacked > .group-left {
  width: 52%;
  div.field-item p:first-child {
    margin-top: 0px;
  }
}

.ds-2col-stacked > .group-right {
  width: 40%;
  padding: 2% 3%;
  border: 1px solid grey;
  border-radius: 4px;
  background-color: $table-background;
}

.section-issues .field-name-field-issue-geocode {
  margin-top: 30px;
}

@media only screen and (max-width: 800px) {
  .ds-2col-stacked > .group-left {
    width: 100%;
    padding: 0;
  }

  .ds-2col-stacked > .group-right {
    width: 100%;
    padding: 5%;
    margin: 10px 0px 30px;
  }
}


.view-mode-fundraising .field-name-field-issue-raised-to-date,
.view-mode-special_issue .field-name-field-issue-raised-to-date,
.view-mode-full .field-name-field-issue-raised-to-date {
  text-align: center;
  font-weight: 900;
  padding-bottom: 0px;
  margin-top: 10px;
  .field-label {
    font-size: 15px;
    line-height: 30px;
  }
  .field-items {
    font-size: 40px;
    line-height: 40px;
  }
}

.view-mode-fundraising .field-name-field-issue-investor-label,
.view-mode-special_issue .field-name-field-issue-investor-label,
.view-mode-full .field-name-field-issue-investor-label {
  margin-top: 20px;
  text-align: center;
  font-weight: 900;
}


// Issuer Questions Block

#block-views-issuer-questions-block-1 {
  background-color: $table-background;
  border: 1px solid black;
  border-radius: $border-radius;
  padding: 5px 30px 30px;
  margin: 40px 0px 20px;
  p {
    margin: 7px 0px 20px;
  }
  .block__title {
    margin-bottom: 0px;
  }
  .button-wrapper {
    height: 45px;
    margin: 10px 0px;
    padding-top: 10px;
    display: block;
  }
  .sppx-button {
    margin: 0px 20px 0px;
    padding: 8px 15px;
  }
  .questions {
    font-size: 14px;
    font-style: italic;
    margin: 3px 0px 10px;
    display: block;
  }
}

#block-views-issuer-questions-block {
  background-color: $table-background;
  border: 1px solid #a8aa00;
  border-radius: $border-radius;
  padding: 5px 30px;
  margin: 20px 0px;
}
.quicktabs_main {
  position: relative;
}
.quicktabs-tabpage {
  display: block;
  visibility: visible;
  width: 100%;
}
.quicktabs-tabpage.quicktabs-hide {
  display: block;
  visibility: hidden;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -1;
}

.ui-helper-reset {
  line-height: 1.5em;
}

.ui-accordion .ui-accordion-content-active,
.ui-accordion .views-accordion-header {
  padding: 1.5em 2em 1.5em 4.5em;
  p {
    margin: 15px 0px;
    margin-bottom: 30px;
  }
}

.ui-accordion span.views-label {  color: #00682D;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 900;
}

.ui-accordion .field-content {
  margin-top: 20px;
}

// We should break this code into it's own file
// TICKER CSS

#block-sppx-ticker-ticker {
  font-family: "Courier New", Courier, monospace;
  margin-top: 10px;
  margin-bottom: 20px;
  .issue-item {
    a {
      text-decoration: none;
    }
    a:hover {
      font-weight: 900;
    }
    font-size: 30px;
    margin-right: 300px;
  }
}

.node-geppetto-smart-document .field {
  width: 60%;
  float: left;
}

.node-geppetto-smart-document .render-pdf {
  width: 300px;
  .help-text {
    font-size: 75%;
  }
}

// Trying to move arrow on right closer to title
// This fixed width is not ideal, but works for now.
div .collapsiblock a {
  width: 250px;
  background-size: 15px;
}

div .collapsiblockCollapsed a {
  background-size: 15px;
}

// Geppetto Template Folders

.page-node-360   .block-quicktabs .views-row .views-field {
  border: none;
}

.page-node-360 .templates div {
  width: 420px;
}

.page-node-360 .templates {


  .wide {
    text-align: left;
  }
  .medium {
    width: 120px;
  }
  .narrow {
    width: 80px;
  }
  a {
    color: $primary-color;
  }
  a:hover {

    color: #a1c6b0;
  }

}

.page-node-360 .templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
}

.page-node-360 .views-row {
  height: 100%;
  border: 0px solid pink;
  padding: 3px 20px;
}

.page-node-360 .views-row.views-row-even {
  background-color: $table-background;
}

.page-node-360 .ui-accordion .ui-accordion-content-active {
  padding: 10px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: #a1c6b0;
}

// CSS For diff feature

.diff td {
  vertical-align : top;
  white-space    : pre;
  white-space    : pre-wrap;
  font-family    : monospace;
}

.diff th {
  background-color: lightgrey;
}

.diff .diffDeleted {
  background-color: pink;
}

.diff .diffInserted {
  background-color: lightgreen;
}

// Temp for Foremost Brewing

.node-317 header {
  display: none;
}

#block-block-12 {
  border: 1px solid grey;
  padding: 2% 3%;
  background-color: #F1F1F1;
  border-radius: 4px;
  margin-top: 120px;
}


#sppx-funding-options-ach-form,
.payment {
  width: 95%;
  padding: 2%;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Responsive video with fixed aspect ratio 16:9. */
/* See https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php */
.embedded-video .player {
  position: relative;
  padding-top: 56.25%; /* =16/9 */
}
.embedded-video .player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 800px) {
  .front .layout-3col__full {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// Maintenance Mode

.maintenance-page.in-maintenance header {
  margin-top: 100px;
}

.maintenance-page.in-maintenance header a,
.maintenance-page.in-maintenance .main-content {
  display: block;
  float: none;
  text-align: center;
  margin: 0 auto;

}

@media only screen and (max-width: 750px) {
  .ds-2col-stacked > .group-left {
      float: none;
      width: 95%;
    margin-left: 0;
    margin-bottom: 5%;
    padding: 2% 3%;
  }

  .embedded-video .player {
    position: relative;
    padding-top: 50%;
    max-width: 95%;
  }

  .field-name-field-issue-photo-gallery {
    max-width: 95%; 
  }

  .ds-2col-stacked > .group-right {
    float: none;
    width: 100%;
    overflow: hidden;
  }

  body .gallery-frame,
  body .galleryformatter .gallery-slides, 
  body .galleryformatter .gallery-thumbs {
    width: 300px !important;
  }
}
