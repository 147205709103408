#slideshow {

  overflow: hidden;
}

ul.slides {

}  

.flexslider {
  margin-bottom: 0 !important; // reset
  border: none !important;

  
  // max height for larger screens
  max-height: 350px;
  overflow: hidden; // x overflow added to stop scrollbar bug caused by direction nav. y overflow for max slider height/

  .slides img {
    position: relative; // needed for vertical centering jquery
  }

  // Fix navigation arrow bug (test)
  .flex-direction-nav a:before {
    font-size: 34px !important;
  }
}

@media only screen and (max-width: 900px) {
  #slideshow {

    overflow: hidden;
    border: 2px solid green;
  }

  ul.slides {

    border: 2px solid green;
  } 

  .flexslider {
    // max height for larger screens
    max-height: 200px;
  }

}

@media only screen and (max-width: 500px) {
  .flexslider {
    // max height for larger screens
    max-height: 150px;
  }
}